/* // -------------- DETAILS MODAL */

.dialog-modal {
  background: #ffffff;
  border-radius: 0.5em;
  box-shadow: 0 10px 20px rgba(black, 0.2);
  left: 50%;
  max-width: 90%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30em;
  text-align: left;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

/* // -------------- CLOSE */

.dialog-modal .dialog-modal-close {
  align-items: center;
  color: #111827;
  display: flex;
  height: 4.5em;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.5em;
}

.dialog-modal .dialog-modal-close svg {
  display: block;
}

/* // -------------- TITLE */

.dialog-modal .dialog-modal-title {
  color: #111827;
  padding: 1.5em 2em;
  pointer-events: all;
  position: relative;
  width: calc(100% - 4.5em);
}
.dialog-modal .dialog-modal-title h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
}
/* // -------------- CONTENT */

.dialog-modal .dialog-modal-content {
  border-top: 1px solid #e0e0e0;
  padding: 2em;
  pointer-events: all;
  overflow: auto;
}
/* // -------------- OVERLAY */
 .dialog-modal-overlay {
  transition: 0.2s ease-out;
  pointer-events: all;
  background: rgba(#0f172a, 0.8);
  position: fixed;
  opacity: 0.5;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  /* // details[open] & {
  //   pointer-events: all;
  //   opacity: 0.5;
  // } */
}

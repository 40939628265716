.dropbtn {
    /* background-color: #4CAF50; */
    /* color: white; */
    background-color: transparent;
    padding: 5px 0 0 5px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
}

.dropbtn span {
    /* background-color: #4CAF50; */
    /* color: white; */
    background-color: transparent;
    display: block;
    /* margin: 5px 16px; */
    font-size: .7rem;
    /* border: none; */
    /* cursor: pointer; */
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    left: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

}

.dropdown-content ul {
    padding: 0;
    margin-bottom: 0;
}

.dropdown-content li {
    color: black;
    padding: 5px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content ul li:hover {
    background-color: #f1f1f1;
}

/* .dropdown:hover .dropdown-content {
    display: block;
} */

.dropdown-content.active {
    display: block;
}

.dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
    background-color: transparent;
}
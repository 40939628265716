.page-link.active  {
    background-color: #d41404 !important;
    color: #ffffff ;
    border-radius: 25%;
}

.page-link:hover  {
    color: #d41404 !important;
    border-radius: 25%;
}

.page-link.active:hover  {
    background-color: #d41404 !important;
    color: #ffffff !important;
    border-radius: 25%;
}
/* Centering the page content */
.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

/* Container for the slider and labels */
.slider-container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 400px;
}

.slider-container h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}


/* Styling the slider */
.slider {
    width: 100%;
    margin: 20px 0;
    height: 8px;
    background: #ddd;
    border-radius: 5px;
    position: relative;
}

.slider:before {
    background-color: #EBEFEB30 !important;
}

/* Styling the track */
.track {
    background: #EBEFEB30;
    height: 8px;
    border-radius: 5px;
}

/* Styling the thumb and vertically centering it */
.thumb {
    height: 20px;
    width: 20px;
    background-color: #d41404;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    position: relative;
    top: -6px; /* Adjusting position to vertically center the thumb */
}

.thumb:hover {
    background-color: #b10e00;
}

/* Label for showing the selected distance */
.distance-label {
    margin-top: 20px;
    font-size: 18px;
    color: #555;
}

.save-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #d41404;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.save-button:hover {
    background-color: #b10e00;
}


/* font-family: 'Open Sans', sans-serif; */

:root {
    --regular: 400;
    --medium: 500;
    --semiBold: 600;
    --bold: 700;
}

body { font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: var(--regular); color: #878787; }

.red-font { color: red; }

.form-control, .form-select { height: 46px; font-size: 16px; box-shadow: none !important; }
.form-select { background-image: url('../images/select-box-arrow.svg'); }
.form-m-b-20 { margin-bottom: 20px; }
.form-label { font-size: 16px; color: #333333; margin-bottom: 12px; }
.col-form-label { font-size: 16px; color: #333333; }
.btn-01 { background-color: #d41404; color: #FFF; text-transform: uppercase; border: 0; padding: 11px 12px; }
.btn-02 { background-color: #FFF; color: #d41404; text-transform: uppercase; border: solid 1px; padding: 11px 12px; }
.btn-01:hover { background-color: #b10e00; color: #FFF; }

.btn-01-success { background-color: #58c11c; color: #FFF; text-transform: uppercase; border: 0; padding: 11px 12px; }
.btn-02-success { background-color: #FFF; color: #58c11c; text-transform: uppercase; border: solid 1px; padding: 11px 12px; }
.btn-01-success:hover { background-color: #58c11c; color: #FFF; }

.btn-03 { background-color: #d41404; color: #FFF; text-transform: uppercase; border: 0; border-radius: .5em; font-size: .8em; display:block }
.btn-03:hover { background-color: #b10e00; color: #FFF; }
.btn-03:focus { background-color: #d41404; color: #FFF; }

.mt-30 { margin-top: 30px; }
.btn-width-150 { width: 150px; }

.full-screen-body { width: 100%; height: 100vh; background: url('../images/login-signup-bg.jpg') no-repeat center center; background-size: cover; overflow-y: auto; padding: 54px 0; position: relative; }
.full-screen-body .logo-main { text-align: center; margin-bottom: 60px; }
.full-screen-body .logo-main img { width: 302px; }

.login-box { width: 100%; background-color: #FFF; border-radius: 20px; padding: 56px 40px 15px; margin: 0 0 55px; }
.login-box .form-m-b { margin-bottom: 16px; position: relative; }
.login-box .form-m-b i:nth-child(2) { position: absolute; top: 0; left: 10px; color: #333333; font-size: 16px; height: 16px; bottom: 0; margin: auto 0; }
.login-box .form-m-b i:nth-child(3) { position: absolute; top: 0; right: 10px; color: #333333; font-size: 16px; height: 16px; bottom: 0; margin: auto 0; }

.login-box .form-m-b .form-control-icon { padding-left: 32px; }
.login-box h1 { font-size: 40px; font-weight: var(--bold); color: #333333; margin: 0 0 16px; text-align: center; }
.login-box .login-sub-title { margin: 0 0 38px; text-align: center; }
.login-box .forgot-pass-link { width: 100%; display: inline-block; text-align: right; text-decoration: none; color: #3b3b3b; margin-bottom: 38px; }
.login-box .note-text { font-size: 13px; color: #5f5f5f; margin: 0 0 14px; }
.login-box .form-check .form-check-label { font-size: 13px; }

.full-screen-body .sign-up-link-box { margin: 0 0 40px; }
.full-screen-body .sign-up-link-box p { font-size: 18px; color: #b5b5b5; margin: 0 0 18px; text-align: center; }
.full-screen-body .sign-up-link-box p a { color: #d41404; text-decoration: none; font-weight: var(--semiBold); }
.full-screen-body .sign-up-link-box ul { width: 100%; margin: 0; padding: 0; list-style-type: none; display: flex; align-items: center; justify-content: center; }
.full-screen-body .sign-up-link-box .signup-btn { display: inline-block; background-color: #2a2a2a; color: #FFF; font-size: 16px; padding: 12px 22px; border-radius: 10px; text-decoration: none; text-align: center; text-transform: uppercase; }
.full-screen-body .sign-up-link-box .signup-btn span { font-size: 20px; color: #f9ad4a; }
.full-screen-body .sign-up-link-box ul li .tooltip-info-link { color: #2a2a2a; margin-left: 10px; display: inline-block; }

.login-footer { width: 100%; text-align: center; }
.login-footer p:first-child { font-size: 14px; color: #bdbdbd; margin: 0 0 15px; }
.login-footer p:last-child { font-size: 12px; color: #878787; margin: 0 0; }

.sidebar-main { width: 306px; height: 100vh; position: fixed; left: 0; top: 0; border-right: 1px solid #ced4da; }
.sidebar-main .inner-logo-main { width: 100%; background-color: #000000; padding: 20px 20px; text-align: center; }
.sidebar-main .inner-logo-main img { width: 200px; }
.sidebar-main .nav-link-main { padding: 44px 44px 44px 20px; }
.sidebar-main .nav-link-main ul { list-style-type: none; padding: 0; margin: 0; }
.sidebar-main .nav-link-main ul li a { font-size: 18px; padding: 10px 12px; border-radius: 6px; color: #333333; display: inline-block; width: 100%; text-decoration: none; margin-bottom: 14px; }
.sidebar-main .nav-link-main ul li a i { margin-right: 5px; color: #a2a2a2; }
.sidebar-main .nav-link-main ul li.active a,
.sidebar-main .nav-link-main ul li a:hover { background-color: #d41404; color: #FFF; }
.sidebar-main .nav-link-main ul li.active a i,
.sidebar-main .nav-link-main ul li a:hover i { color: #FFF; }

/* .top-bar-header { width: 100%; height: 97.96px; padding-left: 346px; padding-right: 38px; border-bottom: 1px solid #ced4da; display: flex; align-items: center; justify-content: space-between; } */
.top-bar-header { width: auto; height: 97.96px; margin-left: 306px; padding-left: 30px; padding-right: 38px; border-bottom: 1px solid #ced4da; display: flex; align-items: center; justify-content: space-between; position: sticky; top:0; z-index: 1020; background-color: #fff;}
.top-bar-header h1 { font-size: 26px; font-weight: var(--bold); color: #333333; margin: 0; }
.top-bar-header .right-side ul { list-style-type: none; padding: 0; margin: 0; display: flex; align-items: center; }
.top-bar-header .right-side ul .user-name-img-li { margin-left: 60px; font-size: 18px; color: #333333; position: relative; }
.top-bar-header .right-side ul .user-name-img-li .user-img { width: 53px; height: 53px; background-color: #ccc; border-radius: 50%; overflow: hidden; display: inline-block; vertical-align: middle; margin-left: 12px;
  background: url('../images/profileLarge.png') no-repeat ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
   background-size: cover;
   position: relative;}
.top-bar-header .right-side ul .user-name-img-li i { position: absolute; bottom: 0; right: 0; color: #d41404; }
.top-bar-header .right-side ul .notification-link { font-size: 18px; color: #333333; margin-left: 60px; }

.wrapper { padding: 30px 40px 30px 346px; }

.create-request-section-one .sub-title { font-size: 16px; color: #333333; margin: 0 0 30px; }
.upload-btn-wrapper { position: relative;  }
.upload-btn-wrapper .btn { border: 1px solid #ced4da; padding: 11px 20px 11px 70px; position: relative; overflow: hidden; }
.upload-btn-wrapper .btn span { width: 50px; height: 100%; background-color: #d41404; color: #FFF; text-align: center; line-height: 46px; position: absolute; top: 0; left: 0; }
.upload-btn-wrapper input[type=file] { width: 100%; height: 100%; position: absolute; left: 0; top: 0; opacity: 0; cursor: pointer; }


.table-main-div .custome-table-head { list-style-type: none; padding: 0 0 0 100px; margin: 0 0 20px; display: flex; width: 100%; }
.custome-tr-main .td-1,
.table-main-div .custome-table-head li:nth-child(1) { width: 25%; }
.custome-tr-main .td-2,
.table-main-div .custome-table-head li:nth-child(2) { width: 25%; }
.custome-tr-main .td-3,
.table-main-div .custome-table-head li:nth-child(3) { width: 10%; }
.custome-tr-main .td-4,
.table-main-div .custome-table-head li:nth-child(4) { width: 10%; }
.custome-tr-main .td-5,
.table-main-div .custome-table-head li:nth-child(5) { width: 20%; }
.custome-tr-main .td-6,
.table-main-div .custome-table-head li:nth-child(6) { width: 10%; }

.custome-tr-main { width: 100%; display: flex; align-items: center; min-height: 60px; padding-left: 100px; background-color: #f9f9f9; border-radius: 6px; position: relative; padding-top: 15px; padding-bottom: 15px; font-size: 15px; color: #333333; margin-bottom: 5px; }
.custome-tr-main .icon-img-main { width: 100px; position: absolute; top: 0; left: 0; bottom: 0; text-align: center; display: flex; align-items: center; }
.custome-tr-main .icon-img-main > div { width: 53px; height: 53px; margin: 0 auto; border-radius: 50%; background-color: #000000; display: inline-block;
  background: url('../images/profileLarge.png') no-repeat ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
   background-size: cover;
   position: relative;
  }
.custome-tr-main .td-1 { font-weight: var(--semiBold); }
.custome-tr-main .td-4 i { font-size: 20px; color: #d41404; vertical-align: middle; margin-right: 10px; }

.custome-tr-main .td-1 { position: relative; }
.custome-tr-main .td-1 p { margin: 0; }
.custome-tr-main .td-1 .translate-btn { background-color: #f5c400; color: #FFF; position: absolute; left: 0; bottom: -15px; padding: 2px 10px; border-radius: 4px 4px 0 0; font-size: 12px;cursor:pointer }
/* .custome-tr-main .td-5 { position: relative; padding-right: 30px; } */
/* .custome-tr-main .td-5 .dot-link { height: 46px; padding: 12px 12px; color: #333333; position: absolute; right: 0; top: 0; bottom: 0; margin: auto 0; } */

.custome-tr-main .td-6 { position: relative; padding-right: 30px; }
.custome-tr-main .td-6 .dot-link { height: 46px; padding: 12px 12px; color: #333333; position: absolute; right: 0; top: 0; bottom: 0; margin: auto 0; }

.change-pass { position: relative; }
.change-pass .form-control { padding-right: 162px; }
.change-pass a { position: absolute; top: 0; right: 15px; bottom: 0; line-height: 46px; color: #333; text-decoration: none; font-weight: var(--medium); }

.user-profile-img-main { display: flex; align-items: center; margin-bottom: 40px; }
.user-profile-img-main .left-img { width: 96px; height: 96px; border-radius: 50%; border: 1px solid #878787; margin-right: 20px; }
.user-profile-img-main .right-content h3 { font-size: 18px; font-weight: var(--semiBold); color: #333; margin: 0 0 5px; }
.user-profile-img-main .right-content ul { list-style-type: none; margin: 0; padding: 0; display: flex; align-items: center; }
.user-profile-img-main .right-content ul li:first-child { margin-left: 0; }
.user-profile-img-main .right-content ul li { margin: 0 5px; }
.user-profile-img-main .right-content ul a { text-decoration: none; color: #333; }

.responses-open-main { background-color: #e5dcdc; width: calc(100% + 80px); padding: 0 0 0 40px; margin-left: -40px; border-radius: 1em; overflow: hidden;}
.chat-thread-list { padding: 30px 0; height: 500px; overflow-y: auto; }
.chat-thread-list .chat-chips-main { width: 100%; background-color: #FFF; border-radius: 10px; padding: 14px 18px; position: relative; display: flex; margin-bottom: 10px; cursor: pointer; }
.chat-thread-list .chat-chips-main.active,
.chat-thread-list .chat-chips-main:hover { background-color: #f6f2f2; }
.chat-thread-list .chat-chips-main .user-img { width: 53px; height: 53px; border-radius: 50%; margin-right: 20px; background-color: #CCC; position: relative; }
.chat-thread-list .chat-chips-main .user-img.online:after { content: ""; width: 13px; height: 13px; border-radius: 50%; background-color: green; position: absolute; right: 3px; bottom: 3px; border: 3px solid #FFF; }
.chat-thread-list .chat-chips-main .user-content h4 { font-size: 16px; color: #2d0000; margin: 0 0 15px; }
.chat-thread-list .chat-chips-main .user-content h4 small { width: 100%; display: inline-block; font-size: 14px; color: #d41404; }
.chat-thread-list .chat-chips-main .user-content p { font-size: 16px; color: #333; margin: 0; }
.chat-thread-list .chat-chips-main .time-box { position: absolute; top: 15px; right: 15px; }
.chat-thread-list .chat-chips-main .translate-btn { background-color: #f5c400; color: #FFF; position: absolute; right: 15px; bottom: 0px; padding: 2px 10px; border-radius: 4px 4px 0 0; font-size: 12px; }

.chat-thread-main { width: 100%; height: 500px; background-color: #FFF; display: flex; flex-direction: column;}
.chat-thread-main .chat-name-box { width: 100%; background-color: #f6f2f2; color: #333; font-weight: var(--semiBold); padding: 14px 18px; display:flex }
.chat-thread-main .chat-input-main { width: 100%; background-color: #f9f9f9; padding: 16px 18px; display: flex; align-items: center; }
.chat-thread-main .chat-input-main a { font-size: 20px; margin-right: 20px; color: #333; text-decoration: none; }
.chat-thread-main .chat-input-main .form-control { border-radius: 42px; padding-right: 50px; }
/* .chat-thread-main .chat-input-main .btn { border: 0 !important; background-color: transparent !important; box-shadow: none !important; color: #d41404; position: absolute; right: 25px; top: 22px; } */
.chat-thread-main .chat-input-main .btn { border: 0 !important; background-color: transparent !important; box-shadow: none !important; color: #d41404; }


.chat-thread-main .chat-name-box { display: flex;}
.chat-thread-main .chat-name-box .user-img { width: 53px; height: 53px; border-radius: 50%; margin-right: 20px; background-color: #CCC; position: relative; }
.chat-thread-main .chat-name-box .user-img.online:after { content: ""; width: 13px; height: 13px; border-radius: 50%; background-color: green; position: absolute; right: 3px; bottom: 3px; border: 3px solid #FFF; }
.chat-thread-main .chat-name-box .user-content h4 { font-size: 16px; color: #2d0000; margin: 0 0 15px; }
.chat-thread-main .chat-name-box .user-details { flex:2;}

.chat-thread-main .chat-chats { font-size: 16px; color: #2d0000; overflow-y: auto; margin:.3em 0; padding:0 1em 0 1em; flex:2; display: flex; flex-direction: column-reverse;}
.chat-thread-main .chat-chats .sender{ font-size: 16px; display: flex; justify-content: end}
.chat-thread-main .chat-chats .sender .message{ border-radius: 1em;  background-color: #f4f8e4; padding:.5em 1em;}
.chat-thread-main .chat-chats .sender .time{ color:#878787; font-size: .7em; float: right; padding: .3em 1em;}
.chat-thread-main .chat-chats .receiver{ font-size: 16px; display: flex; justify-content: start}
.chat-thread-main .chat-chats .receiver .message{ border-radius: 1em;  background-color: #ebf2fb; padding:.5em 1em}
.chat-thread-main .chat-chats .receiver .time{ color:#878787; font-size: .7em; display:flex; justify-content:space-between ; padding: .3em 1em;}
.sort-chats {display:flex; justify-content: space-between;}
.sort-chats .mark-ar {font-size: .9rem; font-weight: 600; color:#52a2fe}
.mark-all-read {font-size: .9rem; font-weight: 600; color:#52a2fe}
.menu-toggle-btn { display: none; }

@media(max-width: 992px){
    .sidebar-main { width: 100%; height: auto; position: static; }
    .sidebar-main .inner-logo-main { text-align: left; display: flex; align-items: center; justify-content: space-between; }
    .menu-toggle-btn { display: inline-block; background-color: #FFF; border-radius: 6px; padding: 8px 16px; color: #333; font-size: 18px; }
    .menu-toggle-btn:hover { background-color: #d41404; color: #FFF; }
    .sidebar-main .nav-link-main { padding: 30px 20px; display: none; position: absolute; width: 100%; background: #FFF; z-index: 1021; border-bottom: 1px solid #f2f2f2; }
    .top-bar-header { padding: 0 20px; margin-left: 0;}
    .wrapper { padding: 30px 20px }
}

@media(max-width: 767px){
    .login-box { padding: 30px 30px; }
    .login-box h1 { font-size: 32px; }
    .top-bar-header { flex-direction: column-reverse; flex-wrap: wrap; height: auto; padding: 15px 20px; margin-left: 0px; position: relative; }
    .top-bar-header .right-side ul .notification-link { margin-left: 0; }
    .top-bar-header .right-side ul { justify-content: space-between; }
    .top-bar-header .left-side, .top-bar-header .right-side { width: 100%; }
    .table-main-div { overflow-x: auto; }
    .custome-tr-main, .table-main-div .custome-table-head { width: 1000px; }
    .top-bar-header .right-side ul .user-name-img-li label { display: none; }
}

.cursor-pointer { cursor: pointer;}
.text-color-ly { color: #d41404; }
.bg-color-ly {background-color: #d41404; }
.text-color-ly-success { color:#58c11c}
/* Input type=number Remove step arrow  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.form-box .form-field {position: relative;}
.form-box .form-field .form-control-icon { padding-left: 45px; }
.form-box .form-field i:nth-child(2) { position: absolute; top: 0px; left: 10px; color: #333333; font-size: 16px; height: 30px; bottom: 0; margin: auto 0;  }
.form-box .form-field i:nth-child(3) { position: absolute; top: 2px; right: 10px; color: #333333; font-size: 16px; height: 16px; bottom: 0; margin: auto 0; }
.show-more{ display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; }
/* .livefeed-subscription-overlay{ position: absolute; display: none; place-items: center; height: 110vh; top: 0; left: 305px; right: 0; bottom: 0; background-color: rgba(205,205,205,0.5); backdrop-filter: blur(4px); -webkit-backdrop-filter: blur(4px);z-index: 2;} */
.livefeed-subscription-overlay{ position: absolute; display: none;  place-items: center; height: 110vh; top: 0; left: 305px; right: 0; bottom: 0; background-image: url('../images/livefeed-overlay-image.png'); background-repeat: no-repeat; background-size: cover; background-position: 0 97px;z-index:2; }
.livefeed-subscription-overlay .message-box{background-color: white !important;color: black;border: 1px solid lightgrey; border-radius: 20px; width:85vh; height:30vh;margin-bottom: 100px; padding: 30px;}
.suggestions-list { position: absolute; z-index: 1000; background-color: white; border: 1px solid #ccc; width: 35%; max-height: 200px; overflow-y: auto; list-style: none; padding: 0; margin: 0; border-radius: 4px; }
.suggestions-list li { padding: 8px; cursor: pointer; }
.suggestions-list li:hover { background-color: #f0f0f0; }